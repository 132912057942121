import { AuthStateChange, FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { BehaviorSubject, Observable, firstValueFrom, map } from 'rxjs';
import { DataService } from '../data/data.service';
import { Device } from '@capacitor/device';
import { FirebaseCollections } from '@app/enums/firebase-collections.enum';
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { LocationService } from '../location/location.service';
import { Session, SessionDTO, SessionStatus, SessionStopDTO } from '@app/models/session';
import { UsersService } from '../users/users.service';
import { environment } from '@environment/environment';

@Injectable({
  providedIn: 'root'
})
export class SessionsService extends DataService {
  public override entity = new BehaviorSubject<Session | null>(null);
  public override entities = new BehaviorSubject<Session[]>([]);
  protected override toastEnabled = false;
  private readonly http: HttpClient = inject(HttpClient);
  private readonly locationService: LocationService = inject(LocationService);
  private readonly usersService: UsersService = inject(UsersService);

  constructor() {
    super();
    // TODO move to parent service
    if (this.usersService.entity.value?.uid && !this.collectionListenerAdded) {
      this.addSessionsListener(this.usersService.entity.value?.uid);
      this.collectionListenerAdded = true;
    }
    FirebaseAuthentication.addListener('authStateChange', (state: AuthStateChange) => {
      this.ngZone.run(async () => {
        if (state.user && !this.collectionListenerAdded) {
          this.addSessionsListener(state.user.uid);
          this.collectionListenerAdded = true;
        } else {
          this.entities.next([]);
          this.collectionListenerAdded = false;
        }
      });
    });
  }

  /*
   * Get all sessions for the current user, ordered by startTimestamp
   */
  public getSessions(): Observable<Session[]> {
    return this.getDocuments(
      `${FirebaseCollections.SESSIONS}`,
      // TODO move to a constant or variable
      {
        type: 'and',
        queryConstraints: [
          {
            type: 'where',
            fieldPath: 'userId',
            opStr: '==',
            value: this.usersService.entity.value?.uid
          }
        ]
      },
      [
        {
          type: 'orderBy',
          fieldPath: 'startTimestamp',
          directionStr: 'desc'
        }
      ]
    ) as Observable<Session[]>;
  }

  public async addSessionsListener(uid: string): Promise<string | null> {
    return this.addCollectionListener(
      `${FirebaseCollections.SESSIONS}`,
      {
        type: 'and',
        queryConstraints: [
          {
            type: 'where',
            fieldPath: 'userId',
            opStr: '==',
            value: uid
          }
        ]
      },
      [
        {
          type: 'orderBy',
          fieldPath: 'startTimestamp',
          directionStr: 'desc'
        }
      ]
    );
  }

  /*
   * Get session by id
   */
  public getSession(id: string): Observable<Session> {
    return this.getDocument(`${FirebaseCollections.SESSIONS}/${id}`) as Observable<Session>;
  }

  /*
   * Get current session with status in progress
   */
  public async getCurrentSession(): Promise<Session | null> {
    return firstValueFrom(
      this.getDocuments(
        `${FirebaseCollections.SESSIONS}`,
        {
          type: 'and',
          queryConstraints: [
            {
              type: 'where',
              fieldPath: 'userId',
              opStr: '==',
              value: this.usersService.entity.value?.uid
            }
          ]
        },
        [
          {
            type: 'orderBy',
            fieldPath: 'startTimestamp',
            directionStr: 'desc'
          }
        ]
      ).pipe(
        map((sessions: any[]) => {
          const filteredSessions = sessions.filter((session: Session) => session && session.status === 'InProgress');
          return filteredSessions.length > 0 ? filteredSessions[0] : null;
        })
      )
    );
  }

  /*
   * Used to add document listener for live updates
   */
  public async addSessionListener(id: string): Promise<string | null> {
    return this.addDocumentListener(`${FirebaseCollections.SESSIONS}/${id}`);
  }

  /*
   * Used to start a new session on the server side and already track specific data like the app version, phone and user id
   * Will return the session object with the id
   */
  public async startSession(): Promise<Session> {
    // add initial location
    const currentLocation = await this.locationService.getCurrentLocation();
    const data = {
      appVersion: environment.version,
      environment: environment.production ? 'production' : 'development',
      locations: [currentLocation],
      startTimestamp: new Date().toISOString(),
      status: SessionStatus.InProgress,
      phone: await Device.getInfo(),
      userId: this.usersService.entity.value?.uid
    } as SessionDTO;
    const doc = await this.addDocument(`${FirebaseCollections.SESSIONS}`, FirebaseCollections.SESSIONS, data);
    this.entity.next(doc as Session);
    return doc as Session;
  }

  /*
   * Used to stop the current session on the server side
   */
  public async stopSession(payload: SessionStopDTO): Promise<Session> {
    // get current entity
    const id = this.entity.getValue()?.id;
    if (id) {
      const session = await this.setDocument(`${FirebaseCollections.SESSIONS}/${id}`, payload);
      return session as Session;
    } else {
      throw new Error('No session to stop');
    }
  }

  /*
   * Used to give the all clear for an emergency and inform emergency contacts.
   */
  public async giveAllClear(id: string): Promise<void> {
    const url = `${environment.firebase.httpEndpoint}/${FirebaseCollections.SESSIONS}/${id}/give-all-clear`;
    const token = await this.usersService.getAuthToken();
    if (!token) {
      throw new Error('No token found');
    }
    await firstValueFrom(this.http.put(url, {}, { headers: { Authorization: token } }));
  }

  /*
   * Used to delete a session
   */
  public async deleteSession(id: string): Promise<void> {
    return this.deleteDocument(`${FirebaseCollections.SESSIONS}/${id}`, FirebaseCollections.SESSIONS);
  }
}
