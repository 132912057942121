/* eslint-disable no-unused-vars */
import { DeviceInfo } from '@capacitor/device';
import { Location } from '@transistorsoft/capacitor-background-geolocation';

/* eslint-disable @typescript-eslint/naming-convention */
export class Session {
  public appVersion: string | undefined;
  public id!: string;
  public endTimestamp?: string;
  public environment: string | undefined;
  public locations: Location[] | undefined;
  public messages?: SessionMessage[];
  public modifiedTimestamp: string | undefined;
  public startTimestamp: string | undefined;
  public status: SessionStatus | undefined;
  public phone: DeviceInfo | undefined;
  public userId: string | undefined;
}

export interface SessionDTO {
  startTimestamp: string;
  status: SessionStatus;
  userId: string;
}

export interface SessionStopDTO {
  endTimestamp: string;
  modifiedTimestamp?: string;
  status: SessionStatus;
}

export class SessionMessage {
  public body: string | undefined;
  public created: string | undefined;
  public modified: string | undefined;
  public status: string | undefined;
  public to:
    | {
        id: string;
        firstName: string;
        lastName: string;
        phone: string;
      }
    | undefined;
  public type: 'all-clear' | 'emergency' | 'test' | undefined;
}

export enum SessionStatus {
  ClearedByUser = 'ClearedByUser',
  Emergency = 'Emergency',
  InProgress = 'InProgress',
  StoppedByUser = 'StoppedByUser',
  Timeout = 'Timeout'
}
