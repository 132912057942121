import { Plugins } from '@capacitor/core';
var NativeModule = Plugins.BackgroundGeolocation;
var LOG_LEVEL_DEBUG = "debug";
var LOG_LEVEL_NOTICE = "notice";
var LOG_LEVEL_INFO = "info";
var LOG_LEVEL_WARN = "warn";
var LOG_LEVEL_ERROR = "error";
var ORDER_ASC = 1;
var ORDER_DESC = -1;
function log(level, msg) {
  return NativeModule.log({
    level: level,
    message: msg
  });
}
function validateQuery(query) {
  if (typeof query !== 'object') return {};
  if (query.hasOwnProperty('start') && isNaN(query.start)) {
    throw new Error('Invalid SQLQuery.start.  Expected unix timestamp but received: ' + query.start);
  }
  if (query.hasOwnProperty('end') && isNaN(query.end)) {
    throw new Error('Invalid SQLQuery.end.  Expected unix timestamp but received: ' + query.end);
  }
  return query;
}
var Logger = /** @class */function () {
  function Logger() {}
  Object.defineProperty(Logger, "ORDER_ASC", {
    get: function () {
      return ORDER_ASC;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(Logger, "ORDER_DESC", {
    get: function () {
      return ORDER_DESC;
    },
    enumerable: false,
    configurable: true
  });
  Logger.debug = function (msg) {
    return log(LOG_LEVEL_DEBUG, msg);
  };
  Logger.error = function (msg) {
    return log(LOG_LEVEL_ERROR, msg);
  };
  Logger.warn = function (msg) {
    return log(LOG_LEVEL_WARN, msg);
  };
  Logger.info = function (msg) {
    return log(LOG_LEVEL_INFO, msg);
  };
  Logger.notice = function (msg) {
    return log(LOG_LEVEL_NOTICE, msg);
  };
  Logger.getLog = function (query) {
    query = validateQuery(query);
    return new Promise(function (resolve, reject) {
      NativeModule.getLog({
        options: query
      }).then(function (result) {
        resolve(result.log);
      }).catch(function (error) {
        reject(error.message);
      });
    });
  };
  Logger.destroyLog = function () {
    return new Promise(function (resolve, reject) {
      NativeModule.destroyLog().then(function () {
        resolve();
      }).catch(function (error) {
        reject(error.message);
      });
    });
  };
  Logger.emailLog = function (email, query) {
    query = validateQuery(query);
    return new Promise(function (resolve, reject) {
      NativeModule.emailLog({
        email: email,
        query: query
      }).then(function (result) {
        resolve(result);
      }).catch(function (error) {
        reject(error.message);
      });
    });
  };
  Logger.uploadLog = function (url, query) {
    query = validateQuery(query);
    return new Promise(function (resolve, reject) {
      NativeModule.emailLog({
        url: url,
        query: query
      }).then(function () {
        resolve();
      }).catch(function (error) {
        reject(error.message);
      });
    });
  };
  return Logger;
}();
export default Logger;
