import { AppConstants } from '@app/app.constants';
import { Capacitor } from '@capacitor/core';
import { Device } from '@models/device';
import { DeviceStatus } from '@app/enums/device-status.enum';
import { DevicesService } from '../devices/devices.service';
import { Injectable, inject } from '@angular/core';
import { Subject, filter, map, takeUntil, tap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from '../users/users.service';
import { environment } from '@environment/environment';
import BackgroundGeolocation, { AuthorizationStatus, Location } from '@transistorsoft/capacitor-background-geolocation';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  private hasPermission = false;
  private isInitialized = false;
  private readonly devicesService: DevicesService = inject(DevicesService);
  private readonly deviceUnsubscribe$: Subject<void> = new Subject();
  private readonly translate: TranslateService = inject(TranslateService);
  private readonly usersService: UsersService = inject(UsersService);

  /*
   * Add a listener to the connected device to handle notifications
   */
  public async initialize(): Promise<void> {
    // first check if background geolocation is initialized
    this.devicesService.connectedDevice$
      .pipe(
        filter((device: Device | null): device is Device => device !== null && device !== undefined),
        map(device => device as Device),
        takeUntil(this.deviceUnsubscribe$),
        filter((device: Device) => device.status !== DeviceStatus.CONNECTING),
        tap(async (device: Device) => await this.handleDeviceState(device))
      )
      .subscribe();
    // add subscriptions
    await this.setupBackgroundGeolocation();
  }

  /*
   * Used to get the current location of the device.
   */
  public async getCurrentLocation(): Promise<Location> {
    return await BackgroundGeolocation.getCurrentPosition({
      timeout: 10, // 30 second timeout to fetch location
      maximumAge: 5000, // Accept the last-known-location if not older than 5000 ms.
      desiredAccuracy: 10, // Try to fetch a location with an accuracy of `10` meters.
      samples: 1
    });
  }

  /*
   * Used to request the location permission.
   */
  public async requestPermissions(): Promise<void> {
    if (Capacitor.isNativePlatform()) {
      if (!this.hasPermission) {
        const status: AuthorizationStatus = await BackgroundGeolocation.requestPermission();
        this.hasPermission = status === 3 || status === 4 ? true : false;
      }
    }
  }

  /*
   * Used to update the background geolocation configuration.
   */
  public async updateConfig(sessionId: string): Promise<void> {
    if (Capacitor.isNativePlatform()) {
      const state = await BackgroundGeolocation.setConfig({
        headers: {
          Authorization: await this.usersService.getAuthToken()
        },
        method: 'PUT',
        url: `${environment.firebase.httpEndpoint}/sessions/${sessionId}/locations`
      });
      console.log('🚀 ~ LocationService ~ updateConfig ~ state:', state);
    }
  }

  /*
   * Used to enable the background geolocation tracking.
   */
  public async startBackgroundGeolocation(): Promise<void> {
    if (Capacitor.isNativePlatform()) {
      if (!this.hasPermission) {
        await this.requestPermissions();
      }
      await BackgroundGeolocation.start();
    }
  }

  /*
   * Used to disable the background geolocation tracking and remove all subscriptions.
   */
  public async stopBackgroundGeolocation(): Promise<void> {
    if (Capacitor.isNativePlatform()) {
      try {
        await BackgroundGeolocation.sync();
      } catch (error) {
        console.error('🚀 ~ LocationService ~ stopBackgroundGeolocation ~ BackgroundGeolocation.sync ~ error:', error);
      }
      await BackgroundGeolocation.stop();
    }
  }

  /*
   * Used to determine if the background geolocation access is granted.
   */
  public async checkPermissions(): Promise<boolean> {
    if (Capacitor.isNativePlatform()) {
      // TODO use proper permission check for location to be set to always on
      const status: AuthorizationStatus = await BackgroundGeolocation.requestPermission();
      // * | [[AUTHORIZATION_STATUS_ALWAYS]]         | iOS & Android |
      // * | [[AUTHORIZATION_STATUS_WHEN_IN_USE]]    | iOS & Android 10+ |
      this.hasPermission = status === 3 || status === 4 ? true : false;
      return this.hasPermission;
    } else {
      return false;
    }
  }

  /*
   * Used to handle the device state.
   * When the device is connected, the user will be asked for permission to send local notifications.
   * When the device is disconnected, the local notifications will be unsubscribed.
   */
  private async handleDeviceState(device: Device): Promise<void> {
    if (!this.hasPermission && device.status === DeviceStatus.CONNECTED) {
      // first get geolocation permission
      await this.requestPermissions();
      await this.setupBackgroundGeolocation();
    }
    if (device.status === DeviceStatus.DISCONNECTED) {
      if (this.deviceUnsubscribe$) {
        this.deviceUnsubscribe$.next();
        this.deviceUnsubscribe$.complete();
      }
    }
  }
  private async setupBackgroundGeolocation(): Promise<void> {
    if (Capacitor.isNativePlatform()) {
      // should only be called once per App installment
      if (this.isInitialized) {
        return;
      }
      try {
        // then make background geolocation ready
        await BackgroundGeolocation.ready({
          autoSyncThreshold: 3, // TODO play around in case locations are not being sent
          batchSync: true,
          backgroundPermissionRationale: {
            title: this.translate.instant('labels_guard_permissions_background_title'),
            message: this.translate.instant('labels_guard_permissions_background_message'),
            positiveAction: this.translate.instant('labels_guard_permissions_positiveAction'),
            negativeAction: this.translate.instant('labels_cancel')
          },
          locationAuthorizationRequest: 'Always',
          locationAuthorizationAlert: {
            titleWhenNotEnabled: this.translate.instant('labels_guard_permissions_background_when_not_enabled_title'),
            titleWhenOff: this.translate.instant('labels_guard_permissions_background_when_off_title'),
            instructions: this.translate.instant('labels_guard_permissions_background_instructions'),
            cancelButton: this.translate.instant('labels_cancel'),
            settingsButton: this.translate.instant('labels_settings')
          },
          notification: {
            channelId: AppConstants.FOREGROUND_SERVICE_ID.toString(),
            channelName: AppConstants.FOREGROUND_SERVICE_NAME,
            sticky: true,
            priority: BackgroundGeolocation.NOTIFICATION_PRIORITY_HIGH,
            smallIcon: 'drawable/notification_alarm_icon',
            // title: this.translate.instant('labels_notifications_device_background_title'),
            // text: this.translate.instant('labels_notifications_device_background_description')
            title: this.translate.instant('labels_notifications_device_background_location_title'),
            text: this.translate.instant('labels_notifications_device_background_location_description')
          },
          // Geolocation Config
          desiredAccuracy: BackgroundGeolocation.DESIRED_ACCURACY_HIGH,
          distanceFilter: environment.production ? 10 : 3,
          maxDaysToPersist: 1,
          // Activity Recognition
          stopTimeout: 5,
          // Application config
          debug: environment.production ? false : true, // <-- enable this hear sounds for background-geolocation life-cycle.
          logLevel: environment.production
            ? BackgroundGeolocation.LOG_LEVEL_OFF
            : BackgroundGeolocation.LOG_LEVEL_VERBOSE,
          stopOnTerminate: false, // <-- Allow the background-service to continue tracking when user closes the app.
          startOnBoot: false, // <-- Auto start tracking when device is powered-up.
          // prevent suspend on iOS
          preventSuspend: true,
          heartbeatInterval: 60,
          showsBackgroundLocationIndicator: true
        });
        this.isInitialized = true;
      } catch (error) {
        console.log('🚀 ~ file: location.service.ts:88 ~ LocationService ~ setupBackgroundGeolocation ~ error', error);
      }
    }
  }
}
