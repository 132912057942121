export var Events = {
  BOOT: "boot",
  TERMINATE: "terminate",
  LOCATION: "location",
  HTTP: "http",
  MOTIONCHANGE: "motionchange",
  PROVIDERCHANGE: "providerchange",
  HEARTBEAT: "heartbeat",
  ACTIVITYCHANGE: "activitychange",
  GEOFENCE: "geofence",
  GEOFENCESCHANGE: "geofenceschange",
  SCHEDULE: "schedule",
  CONNECTIVITYCHANGE: "connectivitychange",
  ENABLEDCHANGE: "enabledchange",
  POWERSAVECHANGE: "powersavechange",
  NOTIFICATIONACTION: "notificationaction",
  AUTHORIZATION: "authorization"
};
