import { Plugins } from '@capacitor/core';
var NativeModule = Plugins.BackgroundGeolocation;
var DEFAULT_URL = 'http://tracker.transistorsoft.com';
var DUMMY_TOKEN = 'DUMMY_TOKEN';
var REFRESH_PAYLOAD = {
  refresh_token: '{refreshToken}'
};
var LOCATIONS_PATH = '/api/locations';
var REFRESH_TOKEN_PATH = '/api/refresh_token';
var TransistorAuthorizationToken = /** @class */function () {
  function TransistorAuthorizationToken() {}
  TransistorAuthorizationToken.findOrCreate = function (orgname, username, url) {
    if (url === void 0) {
      url = DEFAULT_URL;
    }
    return new Promise(function (resolve, reject) {
      NativeModule.getTransistorToken({
        org: orgname,
        username: username,
        url: url
      }).then(function (result) {
        if (result.success) {
          var token = result.token;
          token.url = url;
          resolve(token);
        } else {
          console.warn('[TransistorAuthorizationToken findOrCreate] ERROR: ', result);
          if (result.status == '403') {
            reject(result);
            return;
          }
          resolve({
            accessToken: DUMMY_TOKEN,
            refreshToken: DUMMY_TOKEN,
            expires: -1,
            url: url
          });
        }
      }).catch(function (error) {
        reject(error);
      });
    });
  };
  TransistorAuthorizationToken.destroy = function (url) {
    if (url === void 0) {
      url = DEFAULT_URL;
    }
    return new Promise(function (resolve, reject) {
      NativeModule.destroyTransistorToken({
        url: url
      }).then(function () {
        resolve();
      }).catch(function (error) {
        reject(error.message);
      });
    });
  };
  TransistorAuthorizationToken.applyIf = function (config) {
    if (!config.transistorAuthorizationToken) return config;
    var token = config.transistorAuthorizationToken;
    delete config.transistorAuthorizationToken;
    config.url = token.url + LOCATIONS_PATH;
    config.authorization = {
      strategy: 'JWT',
      accessToken: token.accessToken,
      refreshToken: token.refreshToken,
      refreshUrl: token.url + REFRESH_TOKEN_PATH,
      refreshPayload: REFRESH_PAYLOAD,
      expires: token.expires
    };
    return config;
  };
  return TransistorAuthorizationToken;
}();
export default TransistorAuthorizationToken;
